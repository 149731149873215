<template>
  <div>
    <div class="d-flex align-center body-2 grey--text">
      <v-spacer></v-spacer>
      <TravelersTablePagination :paginationObj="pagination" :dataCount="visitorData.dataSetRowCount" @loadTravelers="loadTravelers" />
    </div>
    <v-data-table
      id="visitor-table"
      :dense="true"
      class="visitor-table"
      :class="[loadingTravelers ? 'loading' : '']"
      :headers="headers"
      :items="visitorsLocal"
      :loading="loadingTravelers"
      @click:row="displayVisitor"
      loading-text="Loading... Please wait"
      disable-pagination
      :hide-default-footer="true"
    >
      <!-- thumbnail -->
      <template v-slot:item.thumb="{ item }">
        <v-icon class="py-3" small> mdi-{{ item.thumb }}</v-icon>
      </template>

      <!-- filter ed id -->
      <template v-slot:header.edCardId="{ header }">
        <div class="d-flex">
          <v-text-field
            class="mt-4"
            dense
            style="max-width: 100px"
            :disabled="loadingTravelers"
            @click.stop
            @keyup.enter="filter(header.value, $event.target.value)"
            clearable
            @click:clear="clearFilter(header.value)"
          ></v-text-field>
          <v-icon x-small>mdi-filter</v-icon>
        </div>
        <div>{{ header.text }}</div>
      </template>

      <!-- filter flight number -->
      <template v-slot:header.travelFlightNumber="{ header }">
        <div class="d-flex">
          <v-text-field
            class="mt-4"
            dense
            style="max-width: 100px"
            :disabled="loadingTravelers"
            @click.stop
            v-model="filterFlightNumber"
            @keyup.enter="filter(header.value, $event.target.value)"
            clearable
            @click:clear="clearFilter(header.value)"
          ></v-text-field>
          <v-icon x-small>mdi-filter</v-icon>
        </div>
        <div>{{ header.text }}</div>
      </template>

      <!-- filter arrival data -->
      <template v-slot:header.travelArrival="{ header }">
        <div class="d-flex">
          <v-menu
            ref="menuDate"
            v-model="travelArrivalDateFilter.menuOpen"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mt-4"
                dense
                style="max-width: 132px"
                :value="computedArrivalDate"
                readonly
                clearable
                @click:clear="clearFilter(header.value)"
                required
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="travelArrivalDateFilter.value" no-title scrollable @change="saveDateFilter($event, header.value)">
            </v-date-picker>
          </v-menu>
          <v-icon x-small>mdi-filter</v-icon>
        </div>
        <div>{{ header.text }}</div>
      </template>
    </v-data-table>

    <div class="py-1 px-4 pagination-header d-flex">
      <v-spacer></v-spacer>
      <TravelersTablePagination :paginationObj="pagination" :dataCount="visitorData.dataSetRowCount" @loadTravelers="loadTravelers" />
    </div>

    <TravelersApprovalDialog
      :travelerSelected="travelerSelected"
      :travelerImage="travelerImage"
      :showApprovalDialog="showApprovalDialog"
      v-if="showApprovalDialog"
    />
  </div>
</template>

<script>
import moment from 'moment';
import TravelersApprovalDialog from '@/views/VisitorApprovalPage/components/TravelersApprovalDialog.vue';
import TravelersTablePagination from '@/views/VisitorApprovalPage/components/TravelersTablePagination.vue';

export default {
  components: {
    TravelersApprovalDialog,
    TravelersTablePagination
  },
  data() {
    return {
      pagination: {
        index: 0,
        pageSize: 30,
        totalPages: null,
        currentPage: 1
      },
      searchFiltersString: null,
      searchFilterArray: [],
      filterFlightNumber: null,
      filterArrivalDate: null,
      travelArrivalDateFilter: {
        menuOpen: false,
        value: null
      },
      date: null,
      loadingTravelers: false,
      travelerImage: null,
      travelerSelected: null,
      showApprovalDialog: false,
      headers: [
        { text: '', align: 'start', sortable: false, value: 'thumb' },
        { text: 'ED Card ID', sortable: false, value: 'edCardId' },
        { text: 'Full Name', sortable: false, value: 'name' },
        { text: 'Flight Number', sortable: false, value: 'travelFlightNumber' },
        { text: 'Travel Arrival Date', sortable: false, value: 'travelArrival' },
        { text: 'Travel Departure Date', sortable: false, value: 'travelDeparture' },
        { text: 'Country of Residency', sortable: false, value: 'countryResidency' },
        { text: 'State of Residency', sortable: false, value: 'stateResidency' },
        { text: 'Travel Origin', sortable: false, value: 'travelOrigin' }
        // { text: 'Test Result', value: 'testResult' },
      ]
    };
  },
  computed: {
    visitorData() {
      return this.$store.state.visitorApprovalPage.visitors;
    },
    visitorsLocal() {
      return this.visitorData.data.map((visitor, index) => {
        return {
          index: index,
          thumb: 'account',
          edCardId: visitor.edCardId,
          name: visitor.firstName + ' ' + visitor.lastName,
          travelFlightNumber: visitor.travelFlightNumber,
          travelArrival: moment(visitor.travelArrival, 'YYYY-MM-DD').format('DD-MMM-YYYY'),
          travelDeparture: moment(visitor.travelDeparture, 'YYYY-MM-DD').format('DD-MMM-YYYY'),
          countryResidency: visitor.countryResidency,
          stateResidency: visitor.stateResidency,
          travelOrigin: visitor.travelOrigin,
          testResult: visitor.testResult,
          id: visitor.id
        };
      });
    },
    userObject() {
      return this.$store.state.userObject;
    },
    computedArrivalDate() {
      return this.date ? moment(this.date).format('D-MMMM-YYYY') : '';
    }
  },
  watch: {
    searchFilterArray(n, o) {
      console.log(n, o);
    }
  },
  created() {
    this.loadTravelers({ currentPage: 1 });
  },
  beforeDestroy() {
    this.$store.commit('RESET_VISITORS_FOR_APPROVAL');
  },
  methods: {
    async loadTravelers({ currentPage }) {
      const index = (currentPage - 1) * this.pagination.pageSize;
      this.$set(this.pagination, 'index', index);
      this.$set(this.pagination, 'currentPage', currentPage);

      this.loadingTravelers = true;
      let payload = {
        index: index,
        pageSize: this.pagination.pageSize,
        filters: this.searchFiltersString ? this.searchFiltersString : null
      };
      try {
        await this.$store.dispatch('getVisitorsForApproval', payload);
        this.pagination.totalPages = Math.ceil(this.visitorData.dataSetRowCount / this.pagination.pageSize);
      } finally {
        this.loadingTravelers = false;
      }
    },
    displayVisitor(visitor) {
      this.resetDisplay();
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'Loading Visitor Data' });
      this.travelerSelected = this.visitorData.data.filter((item) => item.id == visitor.id)[0];
      this.$store.dispatch('getVisitorsForApprovalImage', visitor.id).then((data) => {
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        this.showApprovalDialog = true;
        this.travelerImage = data;
      });
    },
    resetDisplay() {
      this.travelerSelected = null;
      this.travelerImage = null;
    },
    removeTravelerFromList(id) {
      this.$store.commit('REMOVE_VISITOR_APPROVAL_FROM_LIST', id);
    },
    filter(columnKey, value) {
      if (columnKey == 'edCardId') {
        this.filterFlightNumber = null;
        this.date = null;
        this.searchFilterArray = [];
      }
      this.searchFilterArray.push({ key: columnKey, value: value });
      this.searchFiltersString = this.generateFilterUrlParam();
      this.loadTravelers({ currentPage: 1 });
    },
    clearFilter(columnKey, reloadData = true) {
      this.searchFilterArray.splice(
        this.searchFilterArray.findIndex(({ key }) => key == columnKey),
        1
      );

      this.searchFiltersString = this.generateFilterUrlParam();
      if (reloadData) this.loadTravelers({ currentPage: 1 });
    },
    generateFilterUrlParam() {
      if (this.searchFilterArray.length == 0) return null;
      let filtersString = '';
      for (let i = 0; i < this.searchFilterArray.length; i++) {
        filtersString += '&_filter_';
        filtersString += `${this.searchFilterArray[i].key}=${this.searchFilterArray[i].value}`;
      }
      return filtersString;
    },
    saveDateFilter(date, columnKey) {
      console.log(date, columnKey);
      this.$refs.menuDate.save(date);
      this.filter(columnKey, date);
    }
  }
};
</script>

<style lang="scss">
.visitor-table {
  margin-bottom: 10px;
  tr:hover {
    cursor: pointer !important;
  }
  th {
    background: whitesmoke !important;
  }
  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }
}
</style>

<template>
  <div class="d-flex align-center body-2 grey--text">
    <v-text class="me-2"> {{ paginationObj.index + 1 }} - {{ paginationObj.index + paginationObj.pageSize }} of {{ dataCount }} </v-text>
    <v-btn
      :disabled="paginationObj.index == 0 || loadingTravelers"
      fab
      text
      small
      class="me-2"
      @click="$emit('loadTravelers', { currentPage: paginationObj.currentPage - 1 })"
      ><v-icon>mdi-chevron-left</v-icon></v-btn
    >
    <v-btn
      :disabled="paginationObj.currentPage >= paginationObj.totalPages || loadingTravelers"
      fab
      text
      small
      @click="$emit('loadTravelers', { currentPage: paginationObj.currentPage + 1 })"
      ><v-icon>mdi-chevron-right</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  props: ['paginationObj', 'dataCount']
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
</style>

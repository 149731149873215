var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center body-2 grey--text"},[_c('v-spacer'),_c('TravelersTablePagination',{attrs:{"paginationObj":_vm.pagination,"dataCount":_vm.visitorData.dataSetRowCount},on:{"loadTravelers":_vm.loadTravelers}})],1),_c('v-data-table',{staticClass:"visitor-table",class:[_vm.loadingTravelers ? 'loading' : ''],attrs:{"id":"visitor-table","dense":true,"headers":_vm.headers,"items":_vm.visitorsLocal,"loading":_vm.loadingTravelers,"loading-text":"Loading... Please wait","disable-pagination":"","hide-default-footer":true},on:{"click:row":_vm.displayVisitor},scopedSlots:_vm._u([{key:"item.thumb",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"py-3",attrs:{"small":""}},[_vm._v(" mdi-"+_vm._s(item.thumb))])]}},{key:"header.edCardId",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-4",staticStyle:{"max-width":"100px"},attrs:{"dense":"","disabled":_vm.loadingTravelers,"clearable":""},on:{"click":function($event){$event.stopPropagation();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter(header.value, $event.target.value)},"click:clear":function($event){return _vm.clearFilter(header.value)}}}),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-filter")])],1),_c('div',[_vm._v(_vm._s(header.text))])]}},{key:"header.travelFlightNumber",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-4",staticStyle:{"max-width":"100px"},attrs:{"dense":"","disabled":_vm.loadingTravelers,"clearable":""},on:{"click":function($event){$event.stopPropagation();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter(header.value, $event.target.value)},"click:clear":function($event){return _vm.clearFilter(header.value)}},model:{value:(_vm.filterFlightNumber),callback:function ($$v) {_vm.filterFlightNumber=$$v},expression:"filterFlightNumber"}}),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-filter")])],1),_c('div',[_vm._v(_vm._s(header.text))])]}},{key:"header.travelArrival",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_c('v-menu',{ref:"menuDate",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-4",staticStyle:{"max-width":"132px"},attrs:{"dense":"","value":_vm.computedArrivalDate,"readonly":"","clearable":"","required":""},on:{"click:clear":function($event){return _vm.clearFilter(header.value)}}},on))]}}],null,true),model:{value:(_vm.travelArrivalDateFilter.menuOpen),callback:function ($$v) {_vm.$set(_vm.travelArrivalDateFilter, "menuOpen", $$v)},expression:"travelArrivalDateFilter.menuOpen"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){return _vm.saveDateFilter($event, header.value)}},model:{value:(_vm.travelArrivalDateFilter.value),callback:function ($$v) {_vm.$set(_vm.travelArrivalDateFilter, "value", $$v)},expression:"travelArrivalDateFilter.value"}})],1),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-filter")])],1),_c('div',[_vm._v(_vm._s(header.text))])]}}])}),_c('div',{staticClass:"py-1 px-4 pagination-header d-flex"},[_c('v-spacer'),_c('TravelersTablePagination',{attrs:{"paginationObj":_vm.pagination,"dataCount":_vm.visitorData.dataSetRowCount},on:{"loadTravelers":_vm.loadTravelers}})],1),(_vm.showApprovalDialog)?_c('TravelersApprovalDialog',{attrs:{"travelerSelected":_vm.travelerSelected,"travelerImage":_vm.travelerImage,"showApprovalDialog":_vm.showApprovalDialog}}):_vm._e()],1)}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
  <v-row justify="center">
    <v-dialog v-model="showApprovalDialogLocal" scrollable fullscreen>
      <v-card v-if="travelerSelected" style="background: whitesmoke" class="dialog-approval-visitor">
        <v-card-title class="primary white--text">
          Approve Traveler: {{ travelerSelected.firstName }} {{ travelerSelected.lastName }}
          <v-spacer></v-spacer>
          <v-btn icon large @click="$parent.showApprovalDialog = false" color="white"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="fill-height">
          <v-row class="fill-height">
            <!-- IMAGE -->
            <v-col cols="12" md="9" v-if="travelerImage">
              <object v-if="travelerImage.ext == 'pdf'" :data="fileToDisplay" style="width: 100%; height: 100%" />
              <img
                v-else-if="travelerImage.ext == 'jpg' || travelerImage.ext == 'jpeg' || travelerImage.ext == 'png'"
                v-tooltip="'Click to zoom'"
                :src="fileToDisplay"
                class="image-display"
                :style="zoomImage ? 'width:100%;height:auto' : ''"
                alt="No Image"
                @click="zoomImage = !zoomImage"
              />
              <div v-else-if="travelerImage.ext == 'docx' || travelerImage.ext == 'doc'" class="download-wrapper">
                <div class="title">File is of extension ".{{ travelerImage.ext }}"</div>
                <v-btn color="primary" :loading="buttonLoader" @click="downLoadFile(travelerImage.id)"
                  ><v-icon left>mdi-download</v-icon> Download File</v-btn
                >
              </div>
              <v-skeleton-loader v-else class="custom-skeleton-loader" type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="9" v-else>
              <div class="d-flex align-center justify-center full-height"><h2>No Image Available</h2></div>
            </v-col>
            <v-col cols="12" md="3">
              <v-simple-table>
                <tbody style="background: white">
                  <tr v-if="travelerSelected.firstName">
                    <td>First Name</td>
                    <td>{{ travelerSelected.firstName }}</td>
                  </tr>
                  <tr v-if="travelerSelected.lastName">
                    <td>Last Name</td>
                    <td>{{ travelerSelected.lastName }}</td>
                  </tr>
                  <tr v-if="travelerSelected.dateOfBirth">
                    <td>Date fo Birth</td>
                    <td>{{ formatDate(travelerSelected.dateOfBirth) }}</td>
                  </tr>
                  <tr v-if="travelerSelected.edCardId">
                    <td>ED Card ID</td>
                    <td>{{ travelerSelected.edCardId }}</td>
                  </tr>
                  <tr v-if="travelerSelected.travelArrival">
                    <td>Travel Arrival Date</td>
                    <td>{{ formatDate(this.travelerSelected.travelArrival) }}</td>
                  </tr>
                  <tr v-if="travelerSelected.travelDeparture">
                    <td>Travel Departure Date</td>
                    <td>{{ formatDate(travelerSelected.travelDeparture) }}</td>
                  </tr>
                  <tr v-if="travelerSelected.countryResidency">
                    <td>Country of Residency</td>
                    <td>{{ travelerSelected.countryResidency }}</td>
                  </tr>
                  <tr v-if="travelerSelected.stateResidency">
                    <td>State of Residency</td>
                    <td>{{ travelerSelected.stateResidency }}</td>
                  </tr>
                  <tr v-if="travelerSelected.travelOrigin">
                    <td>Travel Origin</td>
                    <td>{{ travelerSelected.travelOrigin }}</td>
                  </tr>
                  <tr v-if="travelerSelected.travelFlightNumber">
                    <td>Travel FlightNumber</td>
                    <td>{{ travelerSelected.travelFlightNumber }}</td>
                  </tr>
                  <tr v-if="travelerSelected.phoneNumber">
                    <td>Phone</td>
                    <td>{{ travelerSelected.phoneNumber }}</td>
                  </tr>
                  <tr v-if="travelerSelected.email">
                    <td>Email</td>
                    <td>{{ travelerSelected.email }}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <div class="mt-2" v-if="!travelerSelected.isReviewed">
                <v-btn color="success" large class="my-2 full-width" @click="visitorApprovalAction('REVIEW_APPROVED')"
                  ><v-icon left>mdi-thumb-up</v-icon> Approve</v-btn
                >
                <v-btn color="error" outlined large class="my-2 full-width" @click="visitorApprovalAction('REVIEW_DENIED')"
                  ><v-icon left>mdi-thumb-down</v-icon> Reject</v-btn
                >
                <v-btn color="info" outlined large class="my-2 full-width" @click="visitorApprovalAction('REVIEW_TEST')"
                  ><v-icon left>mdi-file-find</v-icon> Review</v-btn
                >
              </div>
              <div v-else class="mt-4">
                <v-alert
                  dense
                  text
                  :type="approvalStates[travelerSelected.healthState].color"
                  :color="approvalStates[travelerSelected.healthState].color"
                >
                  {{ approvalStates[travelerSelected.healthState].text }}
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import approvalStates from '@/store/data/aha-approved-states.json';

export default {
  mixins: [SharedFunctions],
  props: ['travelerSelected', 'travelerImage', 'showApprovalDialog'],
  computed: {
    showApprovalDialogLocal: {
      get() {
        return this.showApprovalDialog;
      },
      set(newValue) {
        this.$parent.showApprovalDialog = newValue;
      }
    }
  },
  data() {
    return {
      fileToDisplay: null,
      buttonLoader: false,
      approvalStates: approvalStates,
      zoomImage: false
    };
  },
  mounted() {
    if (this.travelerImage) this.setFileType();
  },
  methods: {
    visitorApprovalAction(action) {
      let payload = {
        body: { id: this.travelerSelected.id, reviewResponse: action },
        actionText: this.getActionText(action)
      };

      this.$store.dispatch('visitorApprovalAction', payload).then(() => {
        this.showApprovalDialogLocal = false;
        this.$parent.removeTravelerFromList(this.travelerSelected.id);
      });
    },
    getActionText(action) {
      if (action == 'REVIEW_APPROVED') return `Traveler: ${this.travelerSelected.firstName} ${this.travelerSelected.lastName} approved`;
      else if (action == 'REVIEW_DENIED') return `Traveler: ${this.travelerSelected.firstName} ${this.travelerSelected.lastName} rejected`;
      else if (action == 'REVIEW_TEST') return `Traveler: ${this.travelerSelected.firstName} ${this.travelerSelected.lastName} set to review test`;
    },
    setFileType() {
      const ext = this.travelerImage.ext;
      if (ext == 'pdf') {
        this.fileToDisplay = 'data:application/pdf;base64,' + this.travelerImage.file;
      } else if (ext == 'jpg' || ext == 'jpeg') {
        this.fileToDisplay = 'data:image/jpeg;base64,' + this.travelerImage.file;
      } else if (ext == 'png') {
        this.fileToDisplay = 'data:image/png;base64,' + this.travelerImage.file;
      }
    },
    downLoadFile(id) {
      this.buttonLoader = true;
      this.$store.dispatch('downloadVisitorApprovalFile', id).then(() => {
        this.buttonLoader = false;
      });
    }
  }
};
</script>

<style lang="scss">
.dialog-approval-visitor {
  .image-display {
    height: calc(100vh - 100px);
    margin: 0 auto;
    display: block;
  }
  .button-group {
    display: flex;
    justify-content: flex-end;
  }
  .custom-skeleton-loader {
    .v-skeleton-loader__image {
      height: 500px !important;
    }
  }

  .download-wrapper {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      margin-top: 10px;
    }
  }
}
</style>